import React from 'react'
// import Header from '../components/header/header'
import SEO from '../components/seo'
import { Link } from 'gatsby'
//form
import BookForm from '../components/forms/book-form'

//swiper
import BookSwiper from '../components/swiper/book-swiper'

//images
import logo from '../images/logo.png'

const RequestFreeCoaching = () => {
  return (
    <>
      {/* <Header /> */}
      <SEO
        title="Turning disability into possibility"
        description="Sandy had it all: a beautiful country home, a successful husband, and the freedom to be a
stay-at-home mom. Then the news crashed down on her like a Tsunami: her infant son Zachary not only had
severe brain damage but probably wouldn’t live past his first birthday"
      />
      <div className="rfcs-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-4 col-md-10 mx-auto"
              style={{ marginTop: '7.5rem' }}
            >
              <Link to="/">
                <img
                  // width={scrollY > 0 ? "100%" : "100%"}
                  id="logo-size"
                  src={logo}
                  alt="Sandy Scott"
                  className="logo sepia img-fluid"
                  style={{ width: '50%' }}
                />
              </Link>
              <div
                className="mt-5"
                // style={{
                //   height: '125vh',
                //   display: 'flex',
                //   flexDirection: 'column',
                //   alignItems: 'center',
                //   justifyContent: 'center',
                //   padding: '8rem 0',
                // }}
              >
                <h1 className="mb-0">Turning disability into possibility</h1>
                <h4 className="my-4">
                  Sandy had it all: a beautiful country home, a successful
                  husband, and the freedom to be a stay-at-home mom. Then the
                  news crashed down on her like a Tsunami: her infant son
                  Zachary not only had severe brain damage but probably wouldn’t
                  live past his first birthday.
                </h4>
                <p>
                  As denial gave way to despair and grief, Sandy looked out over
                  the new, strange world into which her family suddenly found
                  themselves thrown and wondered if they would ever know
                  happiness again.
                </p>
                <p>
                  But it was in the very challenges that Zachary’s condition
                  presented to her that she discovered her own hidden inner
                  resources and a whole new level of compassion among other
                  people. She eventually took on greater roles in the disability
                  community and found her calling as a life coach.
                </p>
                <p>
                  In Lessons from Zachary, Sandy shares the knowledge and
                  insights she gained through her own personal journey of
                  discovery and overcoming obstacles, showing us how to use our
                  strengths to navigate life’s difficult situations to find
                  meaning and lasting fulfillment. Scattered throughout her
                  moving story are multiple useful tools from life coaching—Life
                  Wheel, checklists, Miracle Question, and others—to help us
                  develop our unknown potential.
                </p>
                <div className="text-left w-100 mt-4">
                  <a
                    href="https://www.amazon.com/Lessons-Zachary-Turning-Disability-Possibility-ebook/dp/B01MSW9WSH/ref=sr_1_1?dchild=1&keywords=lessons+from+zachary&qid=1616701317&sr=8-1"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="btn-default btn-purple mr-3"
                  >
                    Buy on Amazon
                  </a>
                  <Link
                    to="/book/#book-form-wrapper"
                    className="btn-default btn-purple mt-lg-0 mt-md-5 mt-4"
                  >
                    Download the PDF
                  </Link>
                </div>
              </div>
              <div
                id="book-form-wrapper"
                style={{
                  height: '90vh',
                  marginTop: '10rem',
                }}
              >
                <BookForm />
              </div>
            </div>
            <div className="col-lg-6 px-0">
              <div
                style={{
                  // height: '100vh',
                  position: 'fixed',
                }}
              >
                <BookSwiper />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RequestFreeCoaching
