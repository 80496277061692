import React from 'react'

import {
  LazyLoadComponent,
  //   LazyLoadImage,
} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

//images
import BookCover from '../../images/new-homepage/book-photo-cover.jpg'
import BookPage1 from '../../images/new-homepage/book-pages/page-1.jpg'
import BookPage2 from '../../images/new-homepage/book-pages/page-2.jpg'
import BookPage3 from '../../images/new-homepage/book-pages/page-3.jpg'
import BookPage4 from '../../images/new-homepage/book-pages/page-4.jpg'
import BookPage5 from '../../images/new-homepage/book-pages/page-5.jpg'
import BookPage6 from '../../images/new-homepage/book-pages/page-6.jpg'
import BookPage7 from '../../images/new-homepage/book-pages/page-7.jpg'
import BookPage8 from '../../images/new-homepage/book-pages/page-8.jpg'
import BookPage9 from '../../images/new-homepage/book-pages/page-9.jpg'

//swiper
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'

// icons
// import LeftArrowIcon from '../../images/new-homepage/left-arrow.svg'
// import RightArrowIcon from '../../images/new-homepage/right-arrow.svg'

const BookSwiper = () => {
  const BookSwiperData = [
    {
      img: BookCover,
    },
    {
      img: BookPage1,
    },
    {
      img: BookPage2,
    },
    {
      img: BookPage3,
    },
    {
      img: BookPage4,
    },
    {
      img: BookPage5,
    },
    {
      img: BookPage6,
    },
    {
      img: BookPage7,
    },
    {
      img: BookPage8,
    },
    {
      img: BookPage9,
    },
  ]

  const articleCarousel = {
    loop: true,
    // spaceBetween: 10,
    speed: 2000,
    autoplay: {
      delay: 10000,
    },
    // autoplay: false,
    disableOnInteraction: false,
    navigation: {
      nextEl: '#article-next',
      prevEl: '#article-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      dynamicBullets: true,
      clickable: true,
    },
    fadeEffect: {
      crossFade: true,
    },
    breakpoints: {
      1366: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      767: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
  }

  return (
    <>
      <div className="request-swiper" style={{ width: '100%', height: '100%' }}>
        {/* <div className="container h-100 w-100"> */}
        {/* <div className="row h-100 w-100"> */}
        {/* <div class="fullwidth-nav d-none">
          <div class="fullwidth-nav-prev" id="article-prev">
            <img src={LeftArrowIcon} alt="Left Arrow" />
          </div>
          <div class="fullwidth-nav-next" id="article-next">
            <img src={RightArrowIcon} alt="Right Arrow" />
          </div>
        </div> */}
        <LazyLoadComponent>
          <Swiper {...articleCarousel}>
            {BookSwiperData.map(page => (
              <div
                className="swiper-slide w-100 text-center"
                style={{ background: '#fff' }}
              >
                <div>
                  <img
                    className="img-fluid"
                    src={page.img}
                    alt="Book Page"
                    style={{
                      height: '100vh',
                      objectFit: 'contain',
                      width: '100%',
                      zIndex: -1,
                      // filter: 'brightness(0.8)',
                      mixBlendMode: 'multiply',
                      // padding: '0 5rem',
                    }}
                  />
                  {/* <div
                  style={{
                    position: 'absolute',
                    bottom: '10%',
                    left: 0,
                    // bottom: 0,
                    zIndex: 1,
                    padding: '0 5rem',
                    width: '85%',
                  }}
                >
                  <p className="text-white review-quote">{group.quote}</p>
                  {(group.role && (
                    <p className="text-white review-role mt-4">{group.role}</p>
                  )) ||
                    null}
                  {(group.logo && (
                    <div
                      className="mt-5"
                      style={{
                        background: '#fff',
                        padding: '1rem',
                        width: '50%',
                      }}
                    >
                      <img
                        src={group.logo}
                        alt="company logo"
                        // style={{ width: '50%' }}
                      />
                    </div>
                  )) ||
                    null}
                </div> */}
                </div>
              </div>
            ))}
          </Swiper>
        </LazyLoadComponent>
        {/* </div> */}
        {/* </div> */}
      </div>
    </>
  )
}

export default BookSwiper
